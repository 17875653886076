import { Link } from 'gatsby'
import React from 'react'
import LogoSmallGold from '../images/logo/logo-small-gold.svg'
import LogoSmallBlack from '../images/logo/logo-small-black.svg'
import FormOverlay from './formOverlay'
import { isMobile } from 'react-device-detect'

const Footer = props => (
  <footer className={`footer`}>
    <div className="footer__inner">
      <div className="footer__logo">
        {props.isProjectsFilterOpen ? <LogoSmallBlack /> : <LogoSmallGold />}
      </div>
      <ul className="footer__links is-size-7">
        <li className="footer__newsletter">
          <FormOverlay linkText="Newsletter" overlayContent="newsletter-signup" />
        </li>
        <li>
          <Link to="/presse/">Presse</Link>
        </li>
        <li>
          <Link to="/karriere/">Karriere</Link>
        </li>
        <li>
          <Link to="/kontakt/#faq">FAQ</Link>
        </li>
        <li>
          <Link to="/imprint-datenschutz/">Datenschutz</Link>
        </li>
        <li>
          <Link to="/imprint-datenschutz/">Imprint</Link>
        </li>
        <li className="footer__copyright">© {new Date().getFullYear()} TAMAX</li>
      </ul>
    </div>
  </footer>
)

export default Footer
