const duration = 0.4
const ease = [0.87, 0, 0.13, 1]

export const content = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: duration,
      delay: duration,
      when: 'beforeChildren',
      ease: ease,
    },
  },
  exit: {
    opacity: 0,
    transition: { duration: duration, ease: ease },
  },
}

export const projectsNav = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: duration,
      delay: 0,
      when: 'beforeChildren',
      ease: ease,
    },
  },
  exit: {
    opacity: 0,
    transition: { duration: duration, ease: ease },
  },
}
