import React, { useState } from 'react'
import axios from 'axios'
import { Radio, RadioGroup, RadioButton } from 'react-custom-radio'
import EllipseTransparent from '../../images/icons/ellipse-transparent.svg'

const GrundstuekAnbieten = () => {
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  })
  const [checkboxState, setCheckboxState] = useState({
    isPrivacyChecked: false,
    typeSelection: null,
  })
  const [messageState, setMessageState] = useState({
    showMessage: false,
    message: '',
  })

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    })
    if (ok) {
      form.reset()
    }
  }

  const handleOnSubmit = e => {
    e.preventDefault()
    const form = e.target
    if (checkboxState.isPrivacyChecked === true) {
      setServerState({ submitting: true })
      axios({
        method: 'post',
        url: 'https://getform.io/f/b14fb0e2-291d-43c8-8411-cfc14352ed1e',
        data: new FormData(form),
      })
        .then(r => {
          handleServerResponse(true, 'Thanks!', form)
        })
        .catch(r => {
          handleServerResponse(false, r.response.data.error, form)
        })
      if (serverState.status && !serverState.status.ok) {
        setMessageState({
          showMessage: true,
          message: 'Leider ist ein Fehler aufgetreten',
        })
      } else {
        setMessageState({
          showMessage: true,
          message: 'Vielen Dank für Ihre Einreichung',
        })
      }
    } else {
      setMessageState({
        showMessage: true,
        message: 'Bitte akzeptieren Sie die Datenschutzvereinbarung',
      })
    }
  }

  const onTypeSelectionChange = e => {
    if (e === checkboxState.typeSelection) {
      setCheckboxState({ typeSelection: null })
    } else {
      setCheckboxState({ typeSelection: e })
    }
  }

  const onPrivacyCheckboxClick = () => {
    setCheckboxState({
      isPrivacyChecked: !checkboxState.isPrivacyChecked,
      typeSelection: checkboxState.typeSelection,
    })
  }

  return (
    <div className="form is-grundstueck-anbieten">
      <div className="section form__inner">
        <div className="form__headline">
          <h1 className="is-size-1 is-size-2-mobile">Grundstück anbieten:</h1>
        </div>
        <form className="form__input" onSubmit={handleOnSubmit}>
          <div className="columns is-marginless">
            <div className="column is-full">
              <input
                type="text"
                className="form-control"
                // id="novalidateform"
                name="name"
                required="required"
                placeholder="Vor- und Nachname"
              />
            </div>
          </div>
          <div className="columns is-marginless">
            <div className="column is-half">
              <input type="text" name="email" placeholder="E-Mail-Adresse" required="required" />
            </div>
            <div className="column is-half">
              <input
                type="text"
                name="telefonnummer"
                placeholder="Telefonnummer"
                required="required"
              />
            </div>
          </div>
          <div className="columns is-marginless">
            <div className="column is-half">
              <input type="text" name="objektart" placeholder="Objektart" required="required" />
            </div>
            <div className="column is-half">
              <input
                type="text"
                name="grundstücksgröße"
                placeholder="Grundstücksgröße (Optional)"
                required="required"
              />
            </div>
          </div>
          <div className="columns is-marginless">
            <div className="column is-full">
              <input
                type="text"
                name="adresse"
                placeholder="Straße, Hausnummer, PLZ, Ort"
                required="required"
              />
            </div>
          </div>
          <div className="columns is-marginless">
            <div className="column is-flex radio-container">
              <div>Ich bin (Bitte Wählen):</div>
              <RadioGroup
                className="radio-group is-size-7"
                name="typ"
                selectedValue={checkboxState.typeSelection}
                onChange={onTypeSelectionChange}
                required="required"
              >
                <RadioButton
                  value="Eigentümer"
                  className={`radio-button ${
                    checkboxState.typeSelection === 'Eigentümer' ? 'is-selected' : ''
                  }`}
                  useHiddenInput={true}
                >
                  <EllipseTransparent />
                  <span>Eigentümer</span>
                </RadioButton>
                <RadioButton
                  className={`radio-button ${
                    checkboxState.typeSelection === 'Makler/Vermieter' ? 'is-selected' : ''
                  }`}
                  value="Makler/Vermieter"
                  useHiddenInput={true}
                >
                  <EllipseTransparent />
                  <span>Makler/Vermieter</span>
                </RadioButton>
                <RadioButton
                  className={`radio-button ${
                    checkboxState.typeSelection === 'Sonstiges' ? 'is-selected' : ''
                  }`}
                  value="Sonstiges"
                  useHiddenInput={true}
                >
                  <EllipseTransparent />
                  <span>Sonstiges</span>
                </RadioButton>
              </RadioGroup>
            </div>
          </div>
          <div className="columns is-marginless">
            <div className="column is-full textarea-container">
              <textarea className="is-size-7" name="nachricht" placeholder="Nachricht" />
            </div>
          </div>
          <div className="columns is-marginless send-container">
            <div className="column is-9 is-flex send-container__privacy is-size-7">
              <div>
                <button
                  className={`send-container__checkbox ${
                    checkboxState.isPrivacyChecked ? 'is-checked' : ''
                  }`}
                  type="button"
                  onClick={onPrivacyCheckboxClick}
                >
                  <EllipseTransparent />
                </button>
              </div>
              <div>
                <div className="send-container__privacy-statement">
                  Ich bin einverstanden, dass meine Daten u.a. zur Bearbeitung meines Anliegens
                  gespeichert und verarbeitet werden. Ich Stimme der Kontaktaufnahme zu. Ich habe
                  die Datenschutzerklärung gelesen.
                </div>
              </div>
            </div>
            <div className="column is-3 send-container__button">
              <button type="submit" className="btn is-bg-beige" disabled={serverState.submitting}>
                Absenden
              </button>
            </div>
          </div>
          <div className={`form__message-container is-size-7`}>
            <span className={messageState.showMessage ? 'has-message' : ''}>
              {messageState.message}
            </span>
          </div>
        </form>
      </div>
    </div>
  )
}

export default GrundstuekAnbieten
