import React from 'react'
import Modal from 'react-modal'
import MenuClose from '../images/icons/menu-close.svg'
import GrundstueckAnbieten from './forms/grundstueck-anbieten'
import NewsletterSignup from './forms/newsletter-signup'
import { CSSTransition } from 'react-transition-group'
import { lock, unlock, clearBodyLocks } from 'tua-body-scroll-lock'

class FormOverlay extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
    this.toggleOverlay = this.toggleOverlay.bind(this)
  }

  toggleOverlay = () => {
    const el = document.getElementById('form-overlay')
    this.setState({ isOpen: !this.state.isOpen }, () => {
      if (this.state.isOpen === true) {
        // lock(el)
        document.documentElement.classList.add('is-form-overlay-open')
      } else {
        // unlock(el)
        document.documentElement.classList.remove('is-form-overlay-open')
      }
    })
  }

  render() {
    const { linkText, overlayContent } = this.props
    return (
      <div>
        <button className="btn" onClick={this.toggleOverlay}>
          {linkText}
        </button>
        <CSSTransition in={this.state.isOpen} timeout={400} classNames="dialog">
          <Modal
            className="form-overlay"
            closeTimeoutMS={400}
            isOpen={this.state.isOpen}
            ref={this.targetRef}
          >
            <div className="form-overlay__nav">
              <button onClick={this.toggleOverlay}>
                <MenuClose />
              </button>
            </div>
            <div className="form-overlay__content">
              {overlayContent === 'grundstueck-anbieten' && <GrundstueckAnbieten />}
              {overlayContent === 'newsletter-signup' && <NewsletterSignup />}
            </div>
          </Modal>
        </CSSTransition>
      </div>
    )
  }
  componentDidMount() {
    if (typeof window === 'undefined') {
      return
    }
  }
  componentWillUnmount() {
    clearBodyLocks()
  }
}

export default FormOverlay
