import React from 'react'
import Navbar from './navbar'
import Footer from './footer'
import Helmet from 'react-helmet'
import classNames from 'classnames'
import '../styles/styles.scss'
import CookieConsent from 'react-cookie-consent'
import { Link } from 'gatsby'
import { animateScroll as scroll } from 'react-scroll'
import { motion, AnimatePresence } from 'framer-motion'
import { content } from '../data/transitions'
import MainContext from './MainContext'
import { isSafari, isMobile, isMobileSafari } from 'react-device-detect'
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import { useLocation } from '@reach/router'

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isCookieBarFaded: false,
      isProjectsFilterOpen: false,
      isProjectsFilterMap: false,
      isFormOverlayOpen: false,
      isBgBeige: false,
      filterCleared: false,
      filterNavString: '',
      setFilterNavString: this.setFilterNavString,
      currentPage: '',
      isPageTransitionComplete: false,
    }
    this.toggleProjectsFilterList = this.toggleProjectsFilterList.bind(this)
    this.toggleProjectsFilterMap = this.toggleProjectsFilterMap.bind(this)
    this.clearFilter = this.clearFilter.bind(this)
  }

  onPageChange = () => {
    const pathname = this.props.location && this.props.location.pathname
    if (pathname !== null) {
      if (this.state.pathname !== pathname) {
        this.setState({
          pathname: pathname,
        })
        this.closeFilter()
      }
    }
  }

  closeFilter = () => {
    if (this.state.isProjectsFilterOpen === true) {
      this.setState({ isProjectsFilterOpen: false })
    }
  }

  clearFilter = () => {
    document.body.classList.add('projects-filter-cleared')
    this.setState({
      filterCleared: true,
    })
    setTimeout(() => {
      this.setState({
        filterCleared: false,
      })
      document.body.classList.remove('projects-filter-cleared')
    }, 300)
  }

  setFilterNavString = filterNavString => {
    if (this.state.filterNavString !== filterNavString) {
      this.setState({ filterNavString })
    }
  }

  toggleProjectsFilterList = () => {
    this.setState({
      isProjectsFilterOpen: !this.state.isProjectsFilterOpen,
      isProjectsFilterMap: false,
    })
    scroll.scrollToTop()
  }

  toggleProjectsFilterMap = () => {
    this.setState({
      isProjectsFilterOpen: !this.state.isProjectsFilterOpen,
      isProjectsFilterMap: true,
    })
  }

  setPageTransitionTimeout = () => {
    this.setState({
      isPageTransitioning: true,
      isPageTransitionComplete: false,
    })
    setTimeout(() => {
      this.setState({
        isPageTransitioning: false,
        isPageTransitionComplete: true,
      })
    }, 400)
  }

  onCookieAccept = () => {
    initializeAndTrack(location)
    this.setState({ isCookieBarFaded: true })
    console.log('cookies accepted')
  }

  render() {
    const {
      children,
      onHideNav,
      onShowNav,
      showNav,
      navTransparent = false,
      bgBeige = false,
      siteTitle,
      hasProjectsNav = false,
      fixedNavOffset = true,
      location,
      footerOffset = false,
    } = this.props
    const pathname = this.props.location && this.props.location.pathname
    const isPageProjekte = pathname === '/projekte/'
    const isProjectsFilterOpen = this.state.isProjectsFilterOpen
    const htmlClasses = classNames({
      // 'has-navbar-fixed-top': fixedNavOffset,
      'is-projects-filter-open': isPageProjekte && isProjectsFilterOpen,
      'is-bg-beige': this.state.isBgBeige,
      'is-safari': isSafari,
      'is-mobile': isMobile,
      'is-mobile-safari': isMobileSafari,
      'is-page-transition-complete': this.state.isPageTransitionComplete,
      'is-page-transitioning': this.state.isPageTransitioning,
    })

    return (
      <>
        <MainContext.Provider value={this.state}>
          <Helmet>
            <html className={htmlClasses} />
          </Helmet>
          <div className="site">
            <Navbar
              siteTitle={siteTitle}
              onHideNav={onHideNav}
              onShowNav={onShowNav}
              showNav={showNav}
              navTransparent={navTransparent}
              location={location}
              filterNavString={this.state.filterNavString}
              isProjectsFilterOpen={this.state.isProjectsFilterOpen}
              isProjectsFilterMap={this.state.isProjectsFilterMap}
              toggleProjectsFilterList={this.toggleProjectsFilterList}
              toggleProjectsFilterMap={this.toggleProjectsFilterList}
              clearFilter={this.clearFilter}
              hasProjectsNav={hasProjectsNav}
            />
            <div
              className="site__content"
              fixedNavOffset={fixedNavOffset}
              setFilterNavString={this.setFilterNavString}
              toggleProjectsFilterList={this.toggleProjectsFilterList}
              // windowWidth={this.state.windowWidth}
            >
              <AnimatePresence exitBeforeEnter onExitComplete={this.setPageTransitionTimeout}>
                <motion.main
                  key={pathname}
                  variants={content}
                  initial="initial"
                  animate="enter"
                  exit="exit"
                >
                  {children}
                </motion.main>
              </AnimatePresence>
            </div>
            <Footer
              isProjectsFilterOpen={this.state.isProjectsFilterOpen}
              footerOffset={footerOffset}
            />
            <CookieConsent
              location="bottom"
              // buttonText="Cookies Aktzeptieren ✓"
              buttonText={'Alle Erlauben'}
              containerClasses={`cookie-consent ${this.state.isCookieBarFaded ? 'is-faded' : ''}`}
              style={{ background: 'transparent' }}
              contentStyle={{ margin: '0px' }}
              buttonClasses={'btn'}
              declineButtonClasses={'btn'}
              declineButtonText={'Nur notwendige Cookies'}
              expires={150}
              disableButtonStyles={true}
              hideOnAccept={false}
              enableDeclineButton
              cookieName="gatsby-gdpr-google-analytics-tamax"
              onAccept={() => {
                this.onCookieAccept()
              }}
            >
              <div className="cookie-consent__info">
                <div>
                  Diese Website verwendet Cookies. Weitere Informationen finden Sie in unserer{' '}
                  <Link to={`/imprint-datenschutz/${isMobile ? '#datenschutzerklaerung' : ''}`}>
                    Datenschutzerklärung
                  </Link>
                  .
                </div>
              </div>
            </CookieConsent>
          </div>
        </MainContext.Provider>
      </>
    )
  }
  componentDidMount() {
    if (typeof window === 'undefined') {
      return
    }
    this.onPageChange()
  }
  componentDidUpdate() {
    this.onPageChange()
  }
  componentWillUnmount = () => {
    window.removeEventListener('resize', this.onResize)
  }
}
export default Layout
