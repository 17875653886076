import React from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import EllipseTransparent from '../../images/icons/ellipse-transparent.svg'
import publicIp from 'public-ip'
import axios from 'axios'

export default class NewsletterSignup extends React.Component {
  constructor() {
    super()
    this.state = {
      email: '',
      name: '',
      isPrivacyChecked: false,
      result: null,
      showMessage: false,
      message: '',
    }
  }

  _handleSubmit = async e => {
    e.preventDefault()
    const { email, name, isPrivacyChecked, showMessage, message } = this.state
    // user data
    const ip = (await publicIp.v4()) || ''
    const ua = navigator.userAgent || ''
    const referrer = document.referrer || ''

    const doi = {
      email: email,
      doidata: {
        user_ip: ip,
        referer: referrer,
        user_agent: ua,
      },
    }
    const subscriberDetails = {
      email: email,
      name: name || '',
      // registered: Date.now(),
      user_ip: ip || '',
      referer: referrer || '',
      user_agent: ua || '',
    }

    if (isPrivacyChecked === true) {
      fetch('/.netlify/functions/newsletter', {
        method: 'POST',
        body: JSON.stringify(subscriberDetails),
      }).then(response => {
        if (response.status === 200) {
          this.setState({ message: 'Danke fürs Abonnieren', showMessage: true })
          // } else if (response.status === 400) {
          //   this.setState({ message: 'Diese E-Mail ist bereits abonniert', showMessage: true })
          //   console.log(response)
        } else {
          this.setState({
            message: 'Entweder sind Sie bereits angemeldet, oder es ist ein Fehler aufgetreten',
            showMessage: true,
          })
        }
      })
    } else {
      this.setState({
        message: 'Bitte akzeptieren Sie die Datenschutzvereinbarung',
        showMessage: true,
      })
    }
  }

  onPrivacyCheckboxClick = () => {
    this.setState({ isPrivacyChecked: !this.state.isPrivacyChecked })
  }

  handleEmailChange = e => {
    this.setState({ email: e.target.value })
  }

  handleNameChange = e => {
    this.setState({ name: e.target.value })
  }

  render() {
    return (
      <div className="form is-newsletter-signup">
        <div className="section form__inner">
          <div className="form__headline">
            <h1 className="is-size-1 is-size-2-mobile">Newsletter abonnieren:</h1>
          </div>
          <form className="form__input" onSubmit={this._handleSubmit}>
            <input
              label="Vor- und Nachname (freiwillig)"
              name="name"
              placeholder="Vor- und Nachname (freiwillig)"
              onChange={this.handleNameChange}
            />
            <input
              label="E-Mail-Adresse"
              name="email"
              type="email"
              required="required"
              placeholder="E-Mail-Adresse"
              onChange={this.handleEmailChange}
            />
            <div className="send-container">
              <div className="send-container__button">
                {/* <button className="btn is-bg-beige" label="Abonnieren" type="submit">
                  Abonnieren
                </button> */}
                <button className="btn is-bg-beige" label="Abonnieren" type="submit">
                  Abonnieren
                </button>
              </div>
              <div className="is-size-7 send-container__privacy">
                <div>
                  <button
                    className={`send-container__checkbox ${
                      this.state.isPrivacyChecked ? 'is-checked' : ''
                    }`}
                    type="button"
                    onClick={() => this.onPrivacyCheckboxClick()}
                  >
                    <EllipseTransparent />
                  </button>
                </div>
                <div>
                  Ich bin einverstanden, dass meine Daten u.a. zur Bearbeitung meines Anliegens
                  gespeichert und verarbeitet werden. Ich Stimme der Kontaktaufnahme zu. Ich habe
                  die Datenschutzerklärung gelesen.
                </div>
              </div>
            </div>
            <div className={`form__message-container is-size-7`}>
              <span className={`${this.state.showMessage ? 'has-message' : ''}`}>
                {this.state.message}
              </span>
            </div>
          </form>
        </div>
      </div>
    )
  }
}
