// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ankauf-js": () => import("./../../../src/pages/ankauf.js" /* webpackChunkName: "component---src-pages-ankauf-js" */),
  "component---src-pages-geschaeftsfelder-js": () => import("./../../../src/pages/geschaeftsfelder.js" /* webpackChunkName: "component---src-pages-geschaeftsfelder-js" */),
  "component---src-pages-grundstueck-anbieten-js": () => import("./../../../src/pages/grundstueck-anbieten.js" /* webpackChunkName: "component---src-pages-grundstueck-anbieten-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-imprint-datenschutz-js": () => import("./../../../src/pages/imprint-datenschutz.js" /* webpackChunkName: "component---src-pages-imprint-datenschutz-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-karriere-js": () => import("./../../../src/pages/karriere.js" /* webpackChunkName: "component---src-pages-karriere-js" */),
  "component---src-pages-karte-js": () => import("./../../../src/pages/karte.js" /* webpackChunkName: "component---src-pages-karte-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-presse-js": () => import("./../../../src/pages/presse.js" /* webpackChunkName: "component---src-pages-presse-js" */),
  "component---src-pages-projekte-js": () => import("./../../../src/pages/projekte.js" /* webpackChunkName: "component---src-pages-projekte-js" */),
  "component---src-pages-region-js": () => import("./../../../src/pages/region.js" /* webpackChunkName: "component---src-pages-region-js" */),
  "component---src-pages-ueber-uns-js": () => import("./../../../src/pages/ueber-uns.js" /* webpackChunkName: "component---src-pages-ueber-uns-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

