import { Link } from 'gatsby'
import React from 'react'
import LogoLargeBlue from '../images/logo/logo-large-blue.svg'
import LogoLargeBlack from '../images/logo/logo-large-black.svg'
import LogoSmallGold from '../images/logo/logo-small-gold.svg'
import PropTypes from 'prop-types'
import MenuOpen from '../images/icons/menu-open.svg'
import MenuClose from '../images/icons/menu-close.svg'
import classNames from 'classnames'
import MainContext from './MainContext'
import { motion, AnimatePresence } from 'framer-motion'
import { projectsNav } from '../data/transitions'
import { isMobile } from 'react-device-detect'

class Navbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobileNavActive: false,
      isTransparent: false,
      hasProjectsNav: false,
      navbarActiveClass: '',
      isMobileNavFadingOut: false,
      isScrolled: false,
    }
    this.oScroll = this.onScroll.bind(this)
  }

  static propTypes = {
    location: PropTypes.object.isRequired,
  }

  isCurrentPage = string => typeof window !== 'undefined' && string === this.state.currentPage

  onPageChange = () => {
    const pathname = this.props.location && this.props.location.pathname
    const currentPage = pathname.replace(/^\/|\/$/g, '')
    const currentSegment = pathname.split('/')[1]
    if (pathname !== null) {
      if (this.state.pathname !== pathname) {
        this.setState({
          pathname: pathname,
          currentPage: currentPage,
          currentSegment: currentSegment,
        })
      }
    }
  }

  onScroll = () => {
    if (window.pageYOffset > 0) {
      this.setState({ isScrolled: true })
    } else {
      this.setState({ isScrolled: false })
    }
  }

  onLinkClick = () => {
    if (this.state.isMobileNavActive === true) {
      // this.setState({ isMobileNavActive: false })
      this.setState({
        isMobileNavFadingOut: true,
      })
      setTimeout(() => {
        this.setState({ isMobileNavFadingOut: false, isMobileNavActive: false })
      }, 400)
    }
  }

  addFilterClearClass = () => {
    document.body.classList.add('filter-cleared')
  }

  toggleMobileNav = () => {
    // this.setState({ isMobileNavActive: !this.state.isMobileNavActive })
    if (this.state.isMobileNavActive === false) {
      this.setState({ isMobileNavActive: true, isMobileNavFadingOut: false })
    } else {
      this.setState({
        isMobileNavFadingOut: true,
      })
      setTimeout(() => {
        this.setState({ isMobileNavFadingOut: false, isMobileNavActive: false })
      }, 400)
    }
  }

  renderInfoDot = string => {
    const dotClasses = classNames({ 'is-current': this.isCurrentPage(string) })
    return <span className={`info-dot info-dot--navbar ${dotClasses}`}>•</span>
  }

  renderProjectsNav = () => {
    const { isProjectsFilterOpen } = this.props
    const { currentPage } = this.state

    return (
      <>
        <AnimatePresence exitBeforeEnter>
          <motion.main
            key={currentPage}
            variants={projectsNav}
            initial="initial"
            animate="enter"
            exit="exit"
          >
            <div className="navbar-projects">
              <div className="navbar-projects__filter-types">
                {this.isCurrentPage('projekte') ? (
                  <button
                    className="navbar-item is-current"
                    onClick={this.props.toggleProjectsFilterList}
                  >
                    Liste
                  </button>
                ) : (
                  <Link
                    className="navbar-item"
                    to="/projekte/#filter"
                    onClick={isProjectsFilterOpen && this.props.toggleProjectsFilterList}
                  >
                    Liste
                  </Link>
                )}
                <Link
                  className={`navbar-item ${this.isCurrentPage('karte') ? 'is-current' : ''}`}
                  to="/karte/"
                >
                  Karte
                </Link>
              </div>
              <div className="navbar-projects__filter-toggle">
                {this.isCurrentPage('projekte') && (
                  <>
                    <button
                      className="navbar-item navbar-item--filter-toggle"
                      onClick={this.props.toggleProjectsFilterList}
                    >
                      {this.props.isProjectsFilterOpen ? (
                        <MenuClose />
                      ) : (
                        <>
                          <MainContext.Consumer>
                            {({ filterNavString }) => (
                              <>
                                <span>
                                  {filterNavString.length
                                    ? isMobile
                                      ? `Filtering`
                                      : `Filter: ${filterNavString}`
                                    : 'Filter'}
                                </span>
                              </>
                            )}
                          </MainContext.Consumer>
                        </>
                      )}
                    </button>
                    <button
                      className="navbar-item navbar-item--filter-clear"
                      onClick={this.props.clearFilter}
                    >
                      <MainContext.Consumer>
                        {({ filterNavString }) => (
                          <>
                            <span>{filterNavString.length ? `Clear` : ''}</span>
                          </>
                        )}
                      </MainContext.Consumer>
                    </button>
                  </>
                )}
              </div>
            </div>
          </motion.main>
        </AnimatePresence>
      </>
    )
  }

  render() {
    const { isProjectsFilterOpen } = this.props
    const { hasProjectsNav } = this.state

    const navClasses = classNames({
      'is-active': this.state.isMobileNavActive,
      'is-fading-out': this.state.isMobileNavFadingOut,
      'is-scrolled': this.state.isScrolled,
    })

    return (
      <>
        <nav
          className={`navbar is-fixed-top is-transparent ${navClasses}`}
          role="navigation"
          aria-label="main navigation"
        >
          <div className="navbar-main">
            <div className="navbar-brand">
              <a className="navbar-item navbar-item--logo" href="/">
                {isProjectsFilterOpen ? <LogoLargeBlack /> : <LogoLargeBlue />}
              </a>
              <a
                role="button"
                className={`navbar-burger burger ${navClasses}`}
                aria-label="menu"
                aria-expanded="false"
                data-target="navMenu"
                onClick={() => this.toggleMobileNav()}
              >
                <div className="nav-toggle-svg nav-toggle-svg--open">
                  <MenuOpen className={navClasses} />
                </div>
                <div className="nav-toggle-svg nav-toggle-svg--close">
                  <MenuClose className={navClasses} />
                </div>
              </a>
            </div>
            <div id="navMenu" className={`navbar-menu ${navClasses}`}>
              <div className="navbar-end">
                <Link
                  className="navbar-item navbar-item--region"
                  to="/region/"
                  onClick={this.onLinkClick}
                >
                  {this.renderInfoDot('region')} Region
                </Link>
                <Link
                  className="navbar-item navbar-item--geschaeftsfelder"
                  to="/geschaeftsfelder/"
                  onClick={this.onLinkClick}
                >
                  {this.renderInfoDot('geschaeftsfelder')} Geschäftsfelder
                </Link>
                <Link
                  className="navbar-item navbar-item--projekte"
                  to="/projekte/"
                  onClick={this.onLinkClick}
                >
                  {this.renderInfoDot('projekte')}
                  {this.renderInfoDot('karte')}
                  {this.state.currentSegment === 'projekte' && (
                    <span className={`info-dot info-dot--navbar is-current`}>•</span>
                  )}
                  Projekte
                </Link>
                <Link
                  className="navbar-item navbar-item--ueber-uns"
                  to="/ueber-uns/"
                  onClick={this.onLinkClick}
                >
                  {this.renderInfoDot('ueber-uns')} Über uns
                </Link>
                <Link
                  className="navbar-item navbar-item--ankauf"
                  to="/ankauf/"
                  onClick={this.onLinkClick}
                >
                  {this.renderInfoDot('ankauf')} Ankauf
                </Link>
                <Link
                  className="navbar-item navbar-item--kontakt"
                  to="/kontakt/"
                  onClick={this.onLinkClick}
                >
                  {this.renderInfoDot('kontakt')} Kontakt
                </Link>
                <Link
                  className="navbar-item navbar-item--karriere"
                  to="/karriere/"
                  onClick={this.onLinkClick}
                >
                  {this.renderInfoDot('karriere')} Karriere
                </Link>
              </div>
              <div className="navbar-footer">
                <LogoSmallGold />
              </div>
            </div>
          </div>
          {/* {this.isCurrentPage('projekte') && this.renderProjectsNav()} */}
          {/* {this.isCurrentPage('karte') && this.renderProjectsNav()} */}
          {this.renderProjectsNav()}
        </nav>
      </>
    )
  }
  componentDidMount() {
    if (typeof window === 'undefined') {
      return
    }
    this.onPageChange()
    if (document.body.classList.contains('has-projects-filter')) {
      this.setState({ hasProjectsNav: true })
    }
    document.addEventListener('scroll', this.onScroll)
  }
  componentDidUpdate() {
    this.onPageChange()
  }
  componentWillUnmount() {
    document.removeEventListener('scroll', this.onScroll)
  }
}

Navbar.contextType = MainContext

export default Navbar
